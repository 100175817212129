/* eslint-disable max-len */
import React, { ReactElement } from 'react'

export default function HTML5(): ReactElement {
  return (
    <svg width="103" height="144" viewBox="0 0 103 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M102.223 28.2994L92.9103 132.418L51.049 144L9.30292 132.434L0 28.2994H102.223Z" fill="#E44D26" />
      <path d="M51.1116 135.147L84.9376 125.788L92.896 36.8139H51.1116V135.147Z" fill="#F16529" />
      <path
        d="M33.0078 62.3576H51.1114V49.5858H51.0672H19.022L19.3283 53.0123L22.4736 88.2076H51.1114V75.436H34.1773L33.0078 62.3576Z"
        fill="#EBEBEB"
      />
      <path
        d="M35.8918 94.5937H28.9663H23.0454L24.8383 114.647L51.0525 121.91L51.1114 121.893V108.606L51.0553 108.62L36.8029 104.78L35.8918 94.5937Z"
        fill="#EBEBEB"
      />
      <path d="M9.51074 0H16.0141V6.4125H21.9632V0H28.4669V19.4186H21.9635V12.9161H16.0144V19.4186H9.51102V0H9.51074Z" fill="black" />
      <path d="M37.0192 6.4395H31.2939V0H49.251V6.4395H43.5232V19.4186H37.0195V6.4395H37.0192Z" fill="black" />
      <path
        d="M52.1011 0H58.8823L63.0537 6.82312L67.2209 0H74.0047V19.4186H67.5281V9.79369L63.0537 16.6981H62.9419L58.4644 9.79369V19.4186H52.1011V0Z"
        fill="black"
      />
      <path d="M77.2412 0H83.7466V12.9999H92.8923V19.4186H77.2412V0Z" fill="black" />
      <path
        d="M51.0674 88.2076H66.8258L65.3403 104.772L51.0674 108.616V121.904L77.3024 114.647L77.4949 112.489L80.5021 78.8656L80.8144 75.436H77.3658H51.0674V88.2076Z"
        fill="white"
      />
      <path
        d="M51.0674 62.3264V62.3576H81.9377H81.9789L82.2356 59.4869L82.8187 53.0123L83.1247 49.5858H51.0674V57.5182V62.3264Z"
        fill="white"
      />
    </svg>
  )
}
