/* eslint-disable max-len */
import React, { ReactElement } from 'react'

export default function Bootstrap(): ReactElement {
  return (
    <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M101 84.1667C101 93.425 93.425 101 84.1667 101H16.8333C7.575 101 0 93.425 0 84.1667V16.8333C0 7.575 7.575 0 16.8333 0H84.1667C93.425 0 101 7.575 101 16.8333V84.1667Z"
        fill="#563D7C"
      />
      <path
        d="M27.4448 21.9493H56.078C61.359 21.9493 65.6004 23.1541 68.7855 25.5801C71.9706 28.006 73.5715 31.6533 73.5715 36.5547C73.5715 39.5253 72.8453 42.0668 71.393 44.1792C69.9407 46.2917 67.8613 47.942 65.1548 49.0972V49.2622C68.7855 50.0379 71.5416 51.7542 73.4064 54.4113C75.2713 57.0848 76.212 60.4184 76.212 64.4452C76.212 66.7557 75.7994 68.9176 74.9742 70.9145C74.1491 72.9279 72.8618 74.6608 71.096 76.113C69.3301 77.5653 67.0857 78.7206 64.3296 79.5787C61.5736 80.4369 58.3059 80.866 54.5102 80.866H27.4448V21.9493ZM37.7594 46.6217H54.5927C57.0682 46.6217 59.1311 45.9286 60.7814 44.5093C62.4318 43.09 63.2569 41.0766 63.2569 38.4361C63.2569 35.4655 62.5143 33.3696 61.029 32.1649C59.5437 30.9601 57.3983 30.3495 54.5927 30.3495H37.7594V46.6217ZM37.7594 72.4493H55.9955C59.1311 72.4493 61.5736 71.6407 63.2899 70.0068C65.0228 68.3895 65.8974 66.0791 65.8974 63.1085C65.8974 60.1874 65.0393 57.9595 63.2899 56.3751C61.5406 54.7908 59.1311 54.0317 55.9955 54.0317H37.7594V72.4493Z"
        fill="white"
      />
    </svg>
  )
}
