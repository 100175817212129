/* eslint-disable max-len */
import React, { ReactElement } from 'react'

export default function Git(): ReactElement {
  return (
    <svg width="169" height="71" viewBox="0 0 169 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M101.285 24.1665C97.5793 24.1665 94.8198 25.9967 94.8198 30.4015C94.8198 33.7202 96.6385 36.024 101.085 36.024C104.854 36.024 107.414 33.7902 107.414 30.2645C107.414 26.2689 105.123 24.1668 101.285 24.1668V24.1665ZM93.8737 55.2668C92.9969 56.3493 92.1231 57.4993 92.1231 58.8567C92.1231 61.5645 95.5562 62.3795 100.272 62.3795C104.179 62.3795 109.5 62.1043 109.5 58.4509C109.5 56.2794 106.94 56.1449 103.705 55.9409L93.8734 55.2668H93.8737ZM113.811 24.3714C115.021 25.9301 116.303 28.0985 116.303 31.2148C116.303 38.7346 110.443 43.1381 101.958 43.1381C99.8001 43.1381 97.8467 42.8677 96.6356 42.5296L94.4121 46.1196L101.012 46.5252C112.666 47.272 119.534 47.6114 119.534 56.6187C119.534 64.4119 112.732 68.8129 101.012 68.8129C88.8213 68.8129 84.1741 65.696 84.1741 60.3447C84.1741 57.2947 85.5214 55.6725 87.8789 53.4334C85.6554 52.488 84.9156 50.7952 84.9156 48.9652C84.9156 47.4737 85.6554 46.1191 86.8697 44.831C88.0816 43.545 89.4283 42.2561 91.045 40.7661C87.745 39.1404 85.2522 35.6174 85.2522 30.6034C85.2522 22.8134 90.3718 17.4633 100.675 17.4633C103.572 17.4633 105.324 17.7315 106.873 18.1405H120.008V23.8976L113.811 24.372"
          fill="#413000"
        />
        <path
          d="M131.864 12.0602C128.024 12.0602 125.802 9.82255 125.802 5.95949C125.802 2.10129 128.024 -0.000497818 131.864 -0.000497818C135.771 -0.000497818 137.994 2.10129 137.994 5.95949C137.994 9.82249 135.771 12.0602 131.864 12.0602ZM123.174 52.7733V47.4239L126.61 46.9511C127.553 46.8136 127.687 46.612 127.687 45.5934V25.6759C127.687 24.9322 127.486 24.4559 126.811 24.2542L123.174 22.9659L123.914 17.4803H137.858V45.5938C137.858 46.6798 137.923 46.8141 138.936 46.9516L142.372 47.4244V52.7738H123.173"
          fill="#413000"
        />
        <path
          d="M169.006 50.1478C166.109 51.5687 161.866 52.8562 158.025 52.8562C150.01 52.8562 146.98 49.6074 146.98 41.9487V24.2002C146.98 23.7946 146.98 23.5224 146.439 23.5224H141.725V17.4932C147.654 16.8139 150.01 13.8324 150.751 6.44804H157.151V16.0714C157.151 16.5439 157.151 16.7486 157.69 16.7486H167.186V23.5223H157.151V39.7132C157.151 43.7109 158.095 45.2678 161.73 45.2678C163.618 45.2678 165.569 44.795 167.186 44.1847L169.006 50.1483"
          fill="#413000"
        />
        <path
          d="M69.2072 32.368L38.4082 1.393C36.6361 -0.391064 33.7596 -0.391064 31.9852 1.393L25.5901 7.82603L33.7021 15.9853C35.5879 15.3449 37.7495 15.7743 39.252 17.2859C40.7623 18.8069 41.1862 20.9996 40.5335 22.9028L48.352 30.7669C50.2435 30.1113 52.4259 30.5349 53.9363 32.0568C56.0479 34.1801 56.0479 37.6207 53.9363 39.7446C51.8243 41.8692 48.4037 41.8692 46.2905 39.7446C44.7028 38.1464 44.31 35.7998 45.1142 33.8321L37.8227 26.498L37.822 45.7977C38.3368 46.054 38.8227 46.3961 39.2517 46.8258C41.3627 48.9489 41.3627 52.3889 39.2517 54.5155C37.1401 56.6386 33.7178 56.6386 31.6084 54.5155C29.4971 52.3891 29.4971 48.9491 31.6084 46.8258C32.1302 46.3019 32.734 45.9053 33.3782 45.6396V26.1605C32.7336 25.8958 32.1306 25.5022 31.608 24.9741C30.0088 23.3668 29.6236 21.006 30.4438 19.0307L22.447 10.9859L1.3307 32.2249C-0.443566 34.0103 -0.443566 36.9036 1.3307 38.6878L32.1279 69.6629C33.901 71.447 36.7765 71.447 38.5521 69.6629L69.2068 38.8342C70.9805 37.0492 70.9805 34.1551 69.2068 32.3706"
          fill="#F05133"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="169" height="71" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
