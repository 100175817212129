/* eslint-disable max-len */
import React, { ReactElement } from 'react'

export default function LESS(): ReactElement {
  return (
    <svg width="112" height="50" viewBox="0 0 112 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M105.967 40.1737C105.967 45.4135 101.68 49.7007 96.4411 49.7007H16.3739C11.1346 49.7007 6.8479 45.4135 6.8479 40.1737V9.83287C6.8479 4.59301 11.1346 0.30583 16.3739 0.30583H96.4411C101.68 0.30583 105.967 4.59301 105.967 9.83287V40.1737Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M89.2179 24.3551C93.4027 25.8193 95.4941 28.3281 95.4941 31.8776C95.4941 34.214 94.6268 36.0684 92.8958 37.44C91.1666 38.8125 88.6999 39.6642 85.489 39.6642C82.2782 39.6642 79.3082 38.7338 76.5728 37.2064C76.6024 36.3964 76.8164 35.5077 77.2094 34.543C77.6024 33.5783 78.0675 32.8119 78.6004 32.2521C81.1145 33.5587 83.3022 34.2132 85.1592 34.2132C86.0369 34.2132 86.716 34.0659 87.1886 33.7692C87.6621 33.4737 87.8975 33.0769 87.8975 32.5784C87.8975 31.6415 87.172 30.9408 85.7255 30.4738L83.1272 29.4923C79.1964 28.0596 77.2308 25.7251 77.2308 22.4823C77.2308 20.1145 78.0565 18.2378 79.7078 16.8494C81.3592 15.4631 83.5849 14.2694 86.3835 14.2694C87.7679 14.2694 89.2923 14.464 90.9601 14.8533C92.6263 15.2435 93.995 16.2045 95.0644 16.7326C95.1274 17.6056 94.9523 18.5639 94.5455 19.6073C94.1359 20.6517 93.6337 21.3913 93.0342 21.8279C90.3905 20.6435 88.1906 20.0513 86.4281 20.0513C85.7971 20.0513 85.3217 20.1913 84.9881 20.473C84.6581 20.7538 84.4959 21.1114 84.4959 21.548C84.4959 22.2959 85.0927 22.8872 86.289 23.3237L89.2179 24.3551Z"
          fill="#F6F6F6"
        />
        <path
          d="M68.3162 24.3551C72.5009 25.8193 74.5915 28.3281 74.5915 31.8776C74.5915 34.214 73.7241 36.0684 71.996 37.44C70.265 38.8125 67.7974 39.6642 64.5874 39.6642C61.3794 39.6642 58.4057 38.7338 55.6711 37.2064C55.7007 36.3964 55.9148 35.5077 56.3086 34.543C56.7016 33.5783 57.1648 32.8119 57.6996 32.2521C60.2165 33.5587 62.4033 34.2132 64.2594 34.2132C65.1378 34.2132 65.817 34.0659 66.2878 33.7692C66.7596 33.4737 66.9967 33.0769 66.9967 32.5784C66.9967 31.6415 66.2712 30.9408 64.8247 30.4738L62.2281 29.4923C58.2964 28.0596 56.33 25.7251 56.33 22.4823C56.33 20.1145 57.1557 18.2378 58.8088 16.8494C60.4592 15.4631 62.684 14.2694 65.4826 14.2694C66.8661 14.2694 68.3941 14.464 70.0584 14.8533C71.7264 15.2435 73.0933 16.2045 74.1635 16.7326C74.2265 17.6056 74.0514 18.5639 73.6446 19.6073C73.2359 20.6517 72.7328 21.3913 72.1342 21.8279C69.4895 20.6435 67.2896 20.0513 65.5263 20.0513C64.8962 20.0513 64.4189 20.1913 64.0871 20.473C63.7582 20.7538 63.5942 21.1114 63.5942 21.548C63.5942 22.2959 64.1918 22.8872 65.3882 23.3237L68.3162 24.3551Z"
          fill="#F6F6F6"
        />
        <path
          d="M53.0737 24.685C53.0737 23.2522 52.8457 21.9279 52.3898 20.7119C51.9349 19.496 51.2815 18.4534 50.4327 17.5804C49.5829 16.7075 48.5534 16.0225 47.3432 15.5239C46.1311 15.0262 44.7707 14.2755 43.2612 14.2755C41.468 14.2755 39.903 15.0874 38.5668 15.7111C37.2306 16.3339 36.1297 17.1902 35.2651 18.281C34.3986 19.3726 33.7537 20.6572 33.3293 22.1372C32.9048 23.6172 32.6926 25.2297 32.6926 26.9739C32.6926 28.7792 32.865 30.4492 33.2116 31.9755C33.5572 33.5 34.1474 34.8234 34.9806 35.9467C35.8146 37.0681 36.8996 37.9402 38.2368 38.562C39.5739 39.1848 41.2336 39.663 43.2148 39.663C46.863 39.663 49.9451 38.6065 52.4618 36.8318C52.2126 35.0265 51.5695 33.5909 50.5363 32.5316C49.251 33.0143 48.1139 33.36 47.128 33.5724C46.1412 33.7819 45.271 33.8875 44.5196 33.8875C43.0155 33.8875 41.9332 33.5048 41.2763 32.7319C40.6201 31.9618 40.2601 31.0508 40.1964 29.6671H52.4637C52.8689 28.3568 53.0737 26.5858 53.0737 24.685ZM40.1496 25.0085C40.2431 23.4182 40.5333 21.7167 41.0161 21.0161C41.4998 20.3146 42.2402 19.9642 43.24 19.9642C44.2696 19.9642 45.0424 20.3368 45.5566 21.0847C46.072 21.8326 46.3294 23.3395 46.3294 24.4923L46.3303 25.0085H40.1496Z"
          fill="#F6F6F6"
        />
        <path
          d="M108.437 28.1177C107.103 29.2826 106.33 30.9591 106.33 33.1509V41.3138C106.33 44.0273 105.496 46.0911 103.612 47.51C101.73 48.927 99.5354 49.6286 97.0306 49.6962L95.5462 49.685V45.6175C96.8547 45.2357 97.392 44.7918 97.9128 44.2876C98.8562 43.4351 99.579 42.0477 99.579 40.121V33.5309C99.6142 31.2909 99.7865 29.5357 100.602 28.2678C101.42 27 103.013 25.8916 105.381 24.9343C102.766 23.7369 101.01 22.1734 100.114 20.2449C99.5882 19.0994 99.5779 17.5536 99.5779 15.6092V9.56585C99.5779 7.56958 98.9246 6.17121 98.1212 5.37319C97.5986 4.85233 96.9907 4.43624 95.5442 4.12214V0.30384C97.3132 0.322372 98.2065 0.302092 99.2369 0.30384C100.823 0.290903 102.152 1.15839 103.309 2.06188C104.568 3.05256 105.463 4.39184 105.988 6.0775C106.286 7.05151 106.329 7.98205 106.329 8.86795V15.8576C106.329 18.3263 107.058 20.1614 108.304 21.3597C109.007 22.0548 109.998 22.6904 111.701 23.2642V26.4217C110.192 26.8388 109.245 27.4041 108.437 28.1177Z"
          fill="#F6F6F6"
          stroke="#404040"
          strokeWidth="5.1509"
          strokeMiterlimit="10"
        />
        <path
          d="M14.6007 0.305948V0.311425C14.0939 0.308628 13.6351 0.306765 13.2682 0.307698C11.6809 0.293828 10.3521 1.16131 9.19664 2.06574C7.93547 3.05641 6.85598 4.34931 6.51779 6.08136C6.32504 7.09709 6.36582 7.98485 6.36582 8.8718V15.8614C6.36582 18.3294 5.44751 20.1634 4.20207 21.3636C3.50051 22.0586 2.003 22.6934 0.299805 23.267V26.4244C1.80932 26.8406 3.26243 27.404 4.06852 28.1195C5.40287 29.2853 6.36466 30.9628 6.36466 33.1544V41.3173C6.36466 44.029 7.01156 46.0948 8.89353 47.5117C10.7756 48.9277 12.969 49.6321 15.4737 49.7006L17.15 49.6894V45.6191C15.8424 45.2364 15.1161 44.7924 14.5925 44.2901C13.65 43.4365 13.1163 42.0501 13.1163 40.1244V33.5333C13.0801 31.2916 12.7188 29.5381 11.9024 28.2694C11.0861 27.0034 9.49311 25.8931 7.12274 24.9367C9.73877 23.7385 11.4938 22.1759 12.389 20.2483C12.9163 19.1019 13.1164 17.5561 13.1164 15.6118V9.56842C13.1164 7.57215 13.5796 6.17378 14.3822 5.37495C14.8177 4.94126 15.5923 4.58439 16.6683 4.29534L18.3697 4.30723C18.3697 2.84266 18.3697 1.92588 18.3697 0.305599H14.601V0.305948H14.6007Z"
          fill="#F6F6F6"
          stroke="#404040"
          strokeWidth="5.1509"
          strokeMiterlimit="10"
        />
        <path
          d="M30.2865 33.4224H29.0124C27.6271 33.4224 27.1053 32.6939 27.1053 31.2307V4.3373C27.1053 2.72016 26.6384 1.58485 26.0405 0.930653C25.4428 0.278205 24.405 0.284733 22.927 0.284733H20.0497L19.7106 0.278206V0.305942L19.7078 4.30863V32.5319C19.7078 34.7765 20.2119 36.4188 21.1088 37.4641C22.0067 38.5076 23.5237 39.0284 25.6624 39.0284C27.1414 39.0284 28.8242 38.8727 30.7108 38.5604C30.8053 38.2452 30.8526 37.5706 30.8526 36.5354C30.8526 35.4974 30.6637 34.4622 30.2865 33.4224Z"
          fill="#F6F6F6"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear" x1="56.4075" y1="0.305828" x2="56.4075" y2="49.7007" gradientUnits="userSpaceOnUse">
          <stop offset="0.1497" stopColor="#2A4F84" />
          <stop offset="0.3884" stopColor="#294E82" />
          <stop offset="1" stopColor="#172E4E" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="112" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
